<template>
  <div class="content-wrapper">
    <bo-page-title>
        <template v-if="isList" slot="additionalCta">
            <b-button @click="addNewPasien" class="btn bg-blue-400 btn-labeled btn-labeled-left ml-1">
            <b><i class="icon-plus2"></i></b> Pendaftaran Pasien Baru
            </b-button>
        </template>
    </bo-page-title>
    <div class="content pt-0">
    
    <b-card v-if="isList" no-body>    
        <div class="card-header">
            <div class="form-row justify-content-end">
              <div class="col-md-4">
                <div class="lbl_status_pasien bg_green">
                  <h3>
                    <i class="icon-users2"></i>
                    {{getTotalPasien||0}} TOTAL PASIEN
                  </h3>
                </div>
              </div>
              <div class="col-md-2">
                <div class="lbl_status_pasien bg_red">
                  <h3>
                    <i class="icon-users2"></i>
                    {{getPasienBayi||0}} PASIEN BAYI
                  </h3>
                </div>
              </div>
              <div class="col-md-2">
                <div class="lbl_status_pasien bg_green">
                  <h3>
                    <i class="icon-users2"></i>
                  {{getPasienAnak||0}} PASIEN ANAK
                  </h3>
                </div>
              </div>
              <div class="col-md-2">
                <div class="lbl_status_pasien bg_red">
                  <h3>
                    <i class="icon-users2"></i>
                    {{getPasienDewasa||0}} PASIEN DEWASA
                  </h3>
                </div>
              </div>
              <div class="col-md-2">
                <div class="lbl_status_pasien bg_green">
                  <h3>
                    <i class="icon-user-check"></i>
                    {{getPasienLansia||0}} PASIEN LANSIA
                  </h3>
                </div>
              </div>
              
            </div>
        </div>
    </b-card>
    <b-card v-if="isList" no-body>
        <b-card-header>
          <b-row>
            <b-col lg="8">
              <b-form-row>
                <b-col lg="4">
                  <b-form-group>
                    <v-select placeholder="Pilih Rentang Umur" @input="doFill" v-model="filter.kategori_umur" :options="Config.mr.rentangUmur" label="text" :reduce="v=>v.value"></v-select>
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <b-form-group>
                    <v-select placeholder="Pilih Jenis Kelamin" @input="doFill" v-model="filter.jenis_kelamin" :options="Config.mr.jenisKelamin" label="text" :reduce="v=>v.value"></v-select>
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <b-form-group>
                    <v-select placeholder="Pilih Status Pasien" @input="doFill" v-model="filter.status_pasien" :options="Config.mr.statusPasien" label="text" :reduce="v=>v.value"></v-select>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-col>
            <b-col lg="4">
              <SearchInput :value.sync="filter.search" @search="doFill" />
            </b-col>
          </b-row>

        </b-card-header>
        <b-card-body class="p-0">
          <b-table
            :fields="fields"
            :items="dataList||[]"
            :per-page="perPage"
            :primary-key="idKey"
            :busy="!dataList"
            responsive
            show-empty
            striped
            small
            bordered
          >
            <template #empty>
              <div class="text-center">
                <h4 align="center"><span v-if="filter.status || filter.search">Hasil pencarian tidak ditemukan</span>
                <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
              </div>
            </template>
            <template #table-busy>
              <div class="skeletal-comp mb-2"></div>
              <div class="skeletal-comp mb-2"></div>
              <div class="skeletal-comp"></div>
            </template>
            <template #cell(number)="v">
              {{(data.per_page*(data.current_page-1))+v.index+1}}
            </template>
            
            <template
              #cell(ap_fullname) = "data"
            >
              <span>{{data.value||"-"}}</span>
            </template>
            
            <template
              #cell(ap_usia_with_ket) = "data"
            >
              <span>{{data.value||"-"}}</span>
            </template>
            
            <template
              #cell(ap_code) = "data"
            >
              <span>{{data.value||"-"}}</span>
            </template>


            <template
              #cell(ap_dob) = "data"
            >
                <span>{{data.item.ap_dob | moment("DD MMM YYYY")}}</span><br/>
                <span class="text-success font-weight-semibold">{{data.item.ap_gol_usia||"-"}}</span>
            </template>

            <template
              #cell(status) = "data"
            >
                <span v-if="data.item.ap_is_draft == 'Y'" :class="`badge bg-warning`">Draft</span>
                <span v-else> - </span>
            </template>

            
            <template
              #cell(kedatangan) = "data"
            >
                <span v-if="data.item.kedatangan">{{data.item.kedatangan | moment("DD MMM YYYY")}}</span>
                <span v-else> - </span>
            </template>

            <template
              #cell(action)="data"
            >   
                <div class="d-flex">
                  <router-link class="btn btn-icon rounded-round btn-sm mr-1 alpha-info border-info"
                  v-if="moduleRole('lihat_detail')"
                  :to="{name: $route.name, params: {pageSlug: data.item[idKey]}, query: {isDetail: true}}"
                  data-toggle="tooltip" data-placement="top" title="View"><i class="icon-eye"></i></router-link>
                  
                  <router-link class="btn btn-icon rounded-round btn-sm mr-1 alpha-info border-info"
                  v-if="moduleRole('lihat_prmrj')"
                  :to="{name: $route.name, params: {pageSlug: data.item[idKey]}, query: {isPrmrj: true}}"
                  data-toggle="tooltip" data-placement="top" title="Lihat PRMRJ"><i class="icon-file-spreadsheet"></i></router-link>
                   
                  <router-link class="btn btn-icon rounded-round btn-sm mr-1 alpha-info border-info"
                  :to="{name: $route.name, params: {pageSlug: data.item[idKey]}}"
                  data-toggle="tooltip" data-placement="top" title="Edit Data PRMRJ"><i class="icon-pencil5"></i></router-link> 

                  <span v-if="!(moduleRole('lihat_detail') || moduleRole('lihat_prmrj'))">-</span>
                </div>
            </template>
          </b-table>
        </b-card-body>
        <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">        
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </b-card-footer>
    </b-card>
    <template v-else>
    <FormPRMRJ v-if="isPrmrj" :row.sync="row" v-bind="passToSub" />
    <FormDetail v-else-if="isDetail" :row.sync="row" v-bind="passToSub" />
    <Form v-else :row.sync="row" v-bind="passToSub" />      
    </template>
    </div>
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Form from './Form.vue'
import FormPRMRJ from './FormPRMRJ.vue'
import FormDetail from './FormDetail.vue'

const _ = global._
const moment = require('moment')
import $ from 'jquery'
import Gen from '@/libs/Gen.js'


export default {
  extends: GlobalVue,
  components:{Form,FormPRMRJ,FormDetail},
  data() {
    return {
      idKey:'ap_id',
      fields: [
        {
          key: 'number',
          label: '#',
        },
        {
          key: 'ap_fullname',
          label: 'NAMA',
        },
        {
          key: 'ap_code',
          label: 'NO. RM',
        },
        {
          key: 'ap_dob',
          label: 'TGL LAHIR',
        },
        {
          key: 'ap_usia_with_ket',
          label: 'USIA',
        },
        {
          key: 'cg_label',
          label: 'JENIS KELAMIN',
        },
        {
          key: 'kedatangan',
          label: 'KEDATANGAN TERAKHIR',
        },
        {
          key: 'status',
          label: 'STATUS',
        },
        {
          key: 'action',
          label: 'Aksi',
          thAttr: { width: 108 }
        },
      ],
      data: {},
      arsip: [],
      mDokterData: [],
      dataPrmrj: [],
      getPasienAnak: 0,
      getPasienBayi: 0,
      getPasienDewasa: 0,
      getPasienLansia: 0,
      getTotalPasien: 0
    }
  },
  computed:{
    passToSub(){
      return {
        Config:this.Config,
        data: this.data,
        arsip: this.arsip,
        mDokterData: this.mDokterData,
        dataPrmrj: this.dataPrmrj,
        mrValidation: this.mrValidation
      }
    },
    pageNo: {
      get:function() {
        return this.filter.page||this.$route.query.page||1
      },
      set:function(newValue) {
        this.$set(this.filter, "shown", this.perPage)
        this.$set(this.filter, "page", newValue)
        this.$router.push({
          name:this.modulePage,
          query:_.clone(this.filter)
        }).catch(()=>{})
      },
    },
    isPrmrj(){
      return this.$route.query.isPrmrj
    },
    isDetail(){
      return this.$route.query.isDetail
    },
    perPage:{
      get:function() {
        return 100
      },
      set:function(newValue) {
        this.pageNo=1
        this.$router.push({
          name:this.modulePage,
          query:{shown:newValue}
        }).catch(()=>{})
      }
    },
  },
  mounted(){
    this.apiGet()
    if(this.isList){
        this.apiGetResume()
    }
    this.filter.kategori_umur = 'ALL'
    this.filter.jenis_kelamin = 'ALL'
    this.filter.status_pasien = 'ALL'

    if(this.$route.params.pageSlug){
      this.validateModuleRoleCrud()
    }
  },
  methods: {
    doFill(){
      this.doFilter()
    },
    downloadPRMRJ(row){
      let data = {exptype: 'xlsx', type: "export", id: row.ap_id}
      let self = this

      $.ajax({
          type: "POST",
          url: process.env.VUE_APP_API_URL + `/report/${'reportPRMRJ'}?token=IXs1029102asoaksoas102901290`,
          data: data,
          cache: false,
          xhrFields:{
              responseType: 'blob'
          },
          success: data => 
          {
              self.loadingOverlay = false
              var link = document.createElement('a')
              link.href = window.URL.createObjectURL(data)
              link.download = `Laporan-PRMRJ-${moment().format("YYYY-MM-DD")}.xlsx`
              link.click()
          },
          fail: data => {
              self.loadingOverlay = false
              alert('Not downloaded')
          }
      })
    },
    
    apiGetResume(){
        let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
        Gen.apiRest(
            "/get/"+this.modulePage+'/resume', 
            {
               params: Object.assign({}, paramsQuery||{})
            }
        ).then(res=>{
            _.forEach(res.data, (v,k)=>{
                this.$set(this, k, v)
            })
        })
    },

    addNewPasien(){
        let data = {
            type : 'init-new-pasien'
        }
        this.loadingOverlay = true
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data:data}, 
            "POST"
        ).then(res=>{
            this.loadingOverlay = false
            let data = res.data.data
            this.$router.push({ name: 'RoPasien', params: { pageSlug: data.ap_id } }).catch(()=>{})
        })
    },
  },
  watch:{
    $route(){
      this.apiGet()
      if(this.isList){
        this.apiGetResume()
      }
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    }
  }
}
</script>
<style>
  .table-sm td, .table-sm th{
    padding: .5rem .75rem;
  }
</style>