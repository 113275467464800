<template>
<li :class="classStep" v-bind:aria-disabled="disabled" v-bind:aria-selected="selected" @click="doClick">
  <a :id="'steps-uid-t-'+option" href="javascript:" v-bind:aria-controls="'steps-uid-p-'+option" class="">
    <span v-if="selected" class="current-info audible">current step: </span>
    <span class="number">{{ option }}</span> <slot></slot>
  </a>
</li>
</template>

<script>

export default{
  name:"VStepLink",
  props:{
    step:{ default:1, type:Number },
    option:{ default:1, type:Number },
    last:{ default:false, type:Boolean },
    clickable: [Boolean,Number,String],
    disableClick: [Boolean,Number,String],
    config:{
      default:()=>{
        return {
          disabled:false,
          validate:false,
          refs:{},
          form:'form',
        }
      },
      type:Object
    },
  },
  data(){
    return {
      opened:false
    }
  },
  mounted() {
    if(this.step == this.option) this.opened = true
    if(this.clickable) this.opened = true
  },
  computed:{
    classStep(){
      var c = ""
      if(this.option==1)
        c += "first "

      if(this.step == this.option)
        c += "current "
      else if(this.step > this.option || this.opened)
        c += "done "
      else if(this.step < this.option || !this.opened)
        c += "disabled "
      
      if(this.last)
        c += "last "
      return c
    },
    selected(){
      var c = this.classStep
      return c.includes("current")
    },
    disabled(){
      var c = this.classStep
      return c.includes("disabled")
    }
  },
  methods:{
    doClick(){
      this.$emit('click:doClick')
      if(!this.opened || this.disableClick) return false
      
      if(this.config.validate && this.config.refs[this.config.form]){
        this.config.refs[this.config.form].validate().then(success => {
          if (!success) { 
            setTimeout(()=>{
                let inv = []
                let el = document.querySelectorAll('.label_error')
                for(let i = 0; i < (el||[]).length; i++){
                    if(el[i].style.display !== 'none'){
                        inv.push(el[i].id)
                    }
                }
                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
            },500)
            return false 
          }
          this.$emit('update:step', this.option)
          this.opened = true
        })
      }else{
        this.$emit('update:step', this.option)
        this.opened = true
      }
    }
  },
  watch:{
    clickable(v){ if(v) this.opened = true },
    step(e){
      if(e == this.option)
        this.opened = true
    }
  }
}
</script>