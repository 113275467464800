<template>
<div>
  <div class="wizard-form steps-basic wizard clearfix">
    <div class="steps clearfix">
      <ul>
        <slot name="link"></slot>
      </ul>
    </div>
    <div class="content clearfix">
      <slot name="content"></slot>
    </div>
    <div class="actions p-0 clearfix mt-3">
      <slot name="action">
        <ul role="menu" aria-label="Pagination">
          <li :class="(step==1?'disabled':'')" v-bind:aria-disabled="(step==1?true:false)" @click="doPrev"><a href="javascript:" :class="'btn btn-light '+(step==1?'disabled':'')" role="menuitem"><i class="icon-arrow-left13 mr-2"></i> Previous</a></li>
          
          <li v-if="step!=last" aria-hidden="false" aria-disabled="false" >
            <a href="javascript:" class="btn btn-primary" role="menuitem" @click="doNext">Next <i class="icon-arrow-right14 ml-2"></i></a>

            <a v-if="draftBtn" @click="$emit('doSaveBtn')" href="javascript:" class="btn btn-success ml-2 mr-2" role="menuitem">{{ draftBtnTxt }}</a>
          </li>

          <li v-if="step==last" aria-hidden="false" aria-disabled="false">
            <button type="submit" v-if="!hideSubmit" class="btn btn-primary" role="menuitem">Submit <i class="icon-checkmark3 ml-2"></i></button>

            <a v-if="draftBtn" @click="$emit('doSaveBtn')" href="javascript:" class="btn btn-success ml-2" role="menuitem">{{ draftBtnTxt }}</a>
          </li>
        </ul>
      </slot>
    </div>
  </div>
</div>
</template>

<script>

export default {
  name:"VStepTab",
  props:{
    step:{
      default:1,
      type:Number
    },
    last:{
      default:1,
      type:Number
    },
    hideSubmit:{
      default:false,
      type:Boolean
    },
    config:{
      default:()=>{
        return {
          disabled:false,
          validate:false,
          refs:{}
        }
      },
      type:Object
    },
    nextTab:{
      type: [Function, Boolean],
      default: false
    },
    draftBtn:{
      default:false,
      type:Boolean
    },
    draftBtnTxt:{
      default:"Save",
      type:String
    }
  },
  methods:{
    doNext(){
      if(this.config.validate && this.config.refs.form){
        this.config.refs.form.validate().then(success => {
          if (!success) { 
            setTimeout(()=>{
                let inv = []
                let el = document.querySelectorAll('.label_error')
                for(let i = 0; i < (el||[]).length; i++){
                    if(el[i].style.display !== 'none'){
                        inv.push(el[i].id)
                    }
                }
                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
            },500)
            return false 
          }

          if(this.nextTab){
            this.nextTab()
          }else{
            this.$emit('update:step',this.step+1)
          }

        })
      }else{
        this.$emit('update:step',this.step+1)
      }
    },
    doPrev(){
      if(this.step<=1) return
      this.$emit('update:step',this.step-1)
    },
  },
  watch:{
    step(e){
      if(e == this.option)
        this.opened = true
    }
  }
}
</script>