<template>
  <div class="card">
    <ul class="nav nav-tabs nav-tabs-highlight nav-justified mb-0">
      <li class="nav-item"><a href="javascript:;" @click="changeTab(1)" data-toggle="tab" data-target="#pasienTabInfo"
          :class="activeTab == 1 ? 'nav-link active' : 'nav-link'">Informasi Pasien</a></li>

      <li class="nav-item"><a href="javascript:;" @click="changeTab(4)" data-toggle="tab" data-target="#pasienTabPJ"
          :class="activeTab == 4 ? 'nav-link active' : 'nav-link'">Informasi Penanggung Jawab</a></li>
      <li class="nav-item"><a href="javascript:;" @click="changeTab(5)" data-toggle="tab" data-target="#pasienTabKW"
          :class="activeTab == 5 ? 'nav-link active' : 'nav-link'">Informasi Kewenangan</a></li>
     
      <li class="nav-item"><a href="javascript:;" @click="changeTab(2)" data-toggle="tab" data-target="#pasienTabRM"
          :class="activeTab == 2 ? 'nav-link active' : 'nav-link'">Riwayat Rekam Medis</a></li>
      <li class="nav-item"><a href="javascript:;" @click="changeTab(3)" data-toggle="tab" data-target="#pasienTabRM"
          :class="activeTab == 3 ? 'nav-link active' : 'nav-link'">Arsip Rekam Medis</a></li>
    </ul>
    <div class="tab-content">
      <div :class="activeTab == 1 ? 'tab-pane fade show active' : 'tab-pane fade'" id="pasienTabInfo">
        <div class="card-header row">
          <div class="col-md-8"> 
            <div class="d-flex align-items-center">
              <h6 class="card-title font-weight-semibold">Informasi Pasien</h6>
            </div>
          </div>
          <div class="col-md-4 text-right">
            <b-button type="button" @click="sinkronData" variant="primary" class="btn-rounded">Sinkronkan Data</b-button>
          </div>
        </div>
        <div class="card-body">
          <table class="table table-bordered table-sm text-uppercase">
            <tbody>
              <tr>
                <td width="33%">
                  <div class="result_tab">
                    <h4>No. Rekam Medis</h4>
                    <p>{{row.ap_code||"-"}}</p>
                  </div>
                </td>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Nama</h4>
                    <p>{{row.ap_fullname||"-"}}</p>
                  </div>
                </td>
                <td width="33%">
                  <div class="result_tab">
                    <h4>NIK</h4>
                    <p>{{row.ap_nik}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Tempat, Tanggal Lahir</h4>
                    <p>{{row.ap_pob||"-"}}, {{row.ap_dob | moment("DD MMM YYYY")}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Usia</h4>
                    <p>{{row.ap_usia_with_ket||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Kategori Usia</h4>
                    <p>{{row.ap_gol_usia||"-"}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Jenis Kelamin</h4>
                    <p>{{row.cg_label||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Agama</h4>
                    <p v-if="row.ma_id !== 99999">{{row.ma_name||"-"}}</p>
                    <p v-else>{{row.ap_agama_text||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Suku</h4>
                    <p>{{row.mrsuk_name||"-"}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Pekerjaan</h4>
                    <p v-if="row.mp_id !== 99999">{{row.mp_name||"-"}}</p>
                    <p v-else>{{row.ap_pekerjaan_text||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Pendidikan</h4>
                    <p>{{row.mpen_name||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Nama Ibu Kandung</h4>
                    <p>{{row.ap_nama_ibu||"-"}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>No. Telpon</h4>
                    <p>{{row.ap_phone_number||"-"}}</p>
                  </div>
                </td>
                <td colspan="2">
                  <div class="result_tab">
                    <h4>Email</h4>
                    <p v-if="row.ap_email">{{row.ap_email.trim()||"-"}}</p>
                    <p v-else> - </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Status Pernikahan</h4>
                    <p v-if="row.ap_status_pernikahan == 1">Belum Kawin</p>
                    <p v-else-if="row.ap_status_pernikahan == 2">Kawin</p>
                    <p v-else-if="row.ap_status_pernikahan == 3">Cerai Hidup</p>
                    <p v-else-if="row.ap_status_pernikahan == 4">Cerai Mati</p>
                    <p v-else> - </p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Bahasa yang Dikuasai</h4>
                    <p>{{row.ap_language||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Golongan Darah</h4>
                    <p v-if="row.ap_gol_darah">{{row.ap_gol_darah.trim()||"-"}}</p>
                    <p v-else> - </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <div class="result_tab">
                    <h4>Alamat Domisili</h4>
                    <p>{{domisiliAddr||"-"}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <div class="result_tab">
                    <h4>Alamat Sesuai KTP</h4>
                    <p>{{ktpAddr||"-"}}</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div :class="activeTab == 2 ? 'tab-pane fade show active' : 'tab-pane fade'" id="pasienTabRM">
        <div class="card-header">
          <div class="d-flex align-items-center">
            <h5 class="card-title font-weight-semibold mr-auto">Rekam Medis No. <span
                class="text-blue-600">{{row.ap_code||"-"}}</span></h5>
            <a href="javascript:;" v-if="(dataList||[]).length" @click="downloadPRMRJ(row)"
              class="btn bg-green-600 btn-labeled btn-labeled-left">
              <b><i class="icon-download"></i></b>
              Unduh PRMRJ
            </a>
          </div>
        </div>

        <div>
          <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false"
            :paginate-elements-by-height="1100" :filename="'Resume Medis'" :pdf-quality="2" :manual-pagination="true"
            pdf-format="a4" pdf-orientation="portrait" pdf-content-width="800px" ref="html2Pdf">
            <div
              style="height:100%;position: relative;font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;color: #333;line-height: 1.5;font-size: 13px;"
              slot="pdf-content">
              <table border="1" style="border-collapse: collapse;width: 100%;"
                v-if="Object.keys(dataRiwayatRMDokter||{}).length">
                <tr>
                  <td style="padding:0;">
                    <table border="1" style="border-collapse: collapse;width: 100%;">
                      <tr>
                        <td width="50%" style="padding:8px 12px;">
                          <div
                            style="float: left;width: 15%;vertical-align: middle;box-sizing: border-box;padding-right: 8px;">
                            <img :src="$parent.assetLocal('global_assets/images/logo-rsh-mark.png')"
                              style="width: 100%;" alt="">
                          </div>
                          <div
                            style="text-transform: uppercase;float: left;width: 85%;vertical-align: middle;box-sizing: border-box;padding-left: 8px;">
                            <h1 style="margin:0;font-size: 18px;line-height: 20px;margin-bottom: 2px;">Rumah Sakit
                              Harapan Magelang</h1>
                            <p style="margin:0;font-size: 13px;">Jl.P Senopati No 11, Magelang 56123</p>
                          </div>
                          <div style="clear: both;"></div>
                        </td>
                        <td width="50%" style="padding:8px 12px;">
                          <div>
                            <table style="border-collapse: collapse;width: 100%;">
                              <tr>
                                <th width="108px" style="text-align:left;">No. RM</th>
                                <td width="12px">:</td>
                                <td>{{row.ap_code||"-"}}</td>
                              </tr>
                              <tr>
                                <th width="108px" style="text-align:left;">Nama Pasien</th>
                                <td width="12px">:</td>
                                <td>{{row.ap_fullname||"-"}} </td>
                                <td>
                                  <div style="display: inline-block;">
                                    <div
                                      style="display: inline-block;text-align: center;border: 2px solid #000;font-size: 13px;width: 20px;height: 20px;box-sizing: border-box;line-height: 16px;vertical-align: middle;">
                                      <span style="line-height: 16px;">
                                        {{row.ap_gender == 1 ? '&check;' : ''}}
                                      </span>
                                    </div>
                                    <div style="display: inline-block;vertical-align: middle;">
                                      L
                                    </div>
                                  </div>
                                  <div style="display: inline-block; margin-left: 12px;">
                                    <div
                                      style="display: inline-block;text-align: center;border: 2px solid #000;font-size: 13px;width: 20px;height: 20px;box-sizing: border-box;line-height: 16px;vertical-align: middle;">
                                      <span style="line-height: 16px;">
                                        {{row.ap_gender == 2 ? '&check;' : ''}}
                                      </span>
                                    </div>
                                    <div style="display: inline-block;vertical-align: middle;">
                                      P
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th width="108px" style="text-align:left;">Tgl. Lahir</th>
                                <td width="12px">:</td>
                                <td>{{ row.ap_dob | moment("DD MMM YYYY")}}</td>
                              </tr>
                            </table>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <h2 style="margin:0;text-align: center;text-transform: uppercase;font-size: 18px;">Resume
                            Medis Rawat Jalan</h2>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" style="padding:0;">
                          <table style="width:100%;">
                            <tr>
                              <td width="50%" style="padding:2px 12px;">
                                <strong>Tgl. Periksa: </strong>
                                {{dataRiwayatRMDokter.row.arm_created_date | moment("dddd, DD MMMM YYYY")}}
                              </td>
                              <td width="50%" style="padding:2px 12px;">
                                <strong>Nama Dokter: </strong>
                                {{dataRiwayatRMDokter.rowReg.bu_full_name||"-"}}
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="padding:0;">
                    <table style="border-collapse: collapse;width: 100%;" border="1">
                      <thead>
                        <tr style="background-color: #d3d3d3;">
                          <th style="text-align: left;padding: 2px 12px;">Ringkasan Riwayat Penyakit</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style="padding: 2px 12px 8px;">
                            <div style="margin-bottom: 4px;"><strong>Keluhan Utama:</strong></div>
                            <p style="margin: 0">{{dataRiwayatRMDokter.row.apsd_keluhan||"-"}}</p>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 2px 12px 8px;">
                            <div style="margin-bottom: 4px;"><strong>Riwayat Penyakit:</strong></div>
                            <p style="margin: 0">{{dataRiwayatRMDokter.row.apsd_anamnesa||"-"}}</p>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 2px 12px 8px;">
                            <div style="margin-bottom: 4px;"><strong>Pemeriksaan Fisik:</strong></div>
                            <p style="margin: 0">{{dataRiwayatRMDokter.row.apod_desc||"-"}}</p>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 2px 12px 8px;">
                            <div style="margin-bottom: 4px;"><strong>Tanda-tanda Vital:</strong></div>
                            <table style="width:100%;border-collapse: collapse;" border="1">
                              <tr>
                                <td style="padding:4px;width:25%;">Tekanan Darah:
                                  {{dataRiwayatRMDokter.row.apod_ttv_tekanan_darah_min}} /
                                  {{dataRiwayatRMDokter.row.apod_ttv_tekanan_darah_max}}</td>
                                <td style="padding:4px;width:25%;">Nadi:
                                  {{dataRiwayatRMDokter.row.apod_ttv_nadi||"-"}} x / menit
                                  {{dataRiwayatRMDokter.row.apod_ttv_label == 'I' ? 'Irreguler' : 'Reguler'}}</td>
                                <td style="padding:4px;width:25%;">Gula Darah:
                                  {{dataRiwayatRMDokter.row.apod_ttv_gula_darah||"-"}} mg/dL</td>
                                <td style="padding:4px;width:25%;">Pernafasan:
                                  {{dataRiwayatRMDokter.row.apod_ttv_pernafasan||"-"}} x/menit</td>
                              </tr>
                              <tr>
                                <td style="padding:4px;width:25%;">SPO2:
                                  {{dataRiwayatRMDokter.row.apod_ttv_spo2||"-"}}%</td>
                                <td style="padding:4px;width:25%;">Suhu:
                                  {{dataRiwayatRMDokter.row.apod_ttv_suhu||"-"}}C</td>
                                <td style="padding:4px;width:25%;">Berat Badan:
                                  {{dataRiwayatRMDokter.row.apod_ttv_weight||"-"}}kg</td>
                                <td style="padding:4px;width:25%;">Tinggi Badan:
                                  {{dataRiwayatRMDokter.row.apod_ttv_height||"-"}}cm</td>
                              </tr>
                              <tr>
                                <td style="padding:4px;width:25%;">Lingkar Kepala:
                                  {{dataRiwayatRMDokter.row.apod_ttv_lingkar_kepala||"-"}}cm</td>
                                <td style="padding:4px;width:25%;">BMI:
                                  {{dataRiwayatRMDokter.row.apod_ttv_bmi||"-"}}m2</td>
                                <td style="padding:4px;width:25%;">Kesadaran:
                                  {{getConfigDynamic(mKesadaran,dataRiwayatRMDokter.row.apod_ttv_kesadaran)||"-"}}</td>
                                <td style="padding:4px;width:25%;" v-if="dataRiwayatRMDokter.row.ap_usia <= 15">Luas
                                  Permukaan Anak:
                                  {{dataRiwayatRMDokter.row.apod_ttv_luas_permukaan_anak||"-"}}kg/m2
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="padding:0;">
                    <table style="border-collapse: collapse;width: 100%;" border="1">
                      <thead>
                        <tr style="background-color: #d3d3d3;">
                          <th colspan="2" style="text-align: left;padding: 2px 12px;">Hasil Pemeriksaan Unit Penunjang
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th width="50%" style="padding: 2px 12px;">Laboratorium</th>
                          <th width="50%" style="padding: 2px 12px;">Radiologi</th>
                        </tr>
                        <tr>
                          <td style="padding:2px 12px;">
                            <ul style="margin:0;padding-left: 16px;"
                              v-if="(dataRiwayatRMDokter.row.appdl_hasil||[]).length">
                              <li v-for="(v,k) in (dataRiwayatRMDokter.row.appdl_hasil||[])" :key="k">
                                {{v.value||"-"}}
                              </li>
                            </ul>
                            <span v-else> - </span>
                          </td>
                          <td style="padding:2px 12px;">
                            <ul style="margin:0;padding-left: 16px;"
                              v-if="(dataRiwayatRMDokter.row.appdr_hasil||[]).length">
                              <li v-for="(v,k) in (dataRiwayatRMDokter.row.appdr_hasil||[])" :key="k">
                                {{v.value||"-"}}
                              </li>
                            </ul>
                            <span v-else> - </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="padding:0;">
                    <table style="border-collapse: collapse;width: 100%;" border="1">
                      <thead>
                        <tr style="background-color: #d3d3d3;">
                          <th colspan="2" style="text-align: left;padding: 2px 12px;">Diagnosa Dokter</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th width="50%" style="padding: 2px 12px;">Diagnosa</th>
                          <th width="50%" style="padding: 2px 12px;">ICD10</th>
                        </tr>
                        <tr v-for="(v,k) in (dataRiwayatRMDokter.dataICD10||[])" :key="k">
                          <td style="padding:2px 12px;">
                            <span>{{v.api10_diagnosis||"-"}}</span>
                          </td>
                          <td style="padding:2px 12px;">
                            <span>{{v.mi10_code||"-"}} - {{v.mi10_name||"-"}}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="padding:0;">
                    <table style="border-collapse: collapse;width: 100%;" border="1">
                      <thead>
                        <tr style="background-color: #d3d3d3;">
                          <th colspan="2" style="text-align: left;padding: 2px 12px;">Tindakan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th width="50%" style="padding: 2px 12px;">Tindakan</th>
                          <th width="50%" style="padding: 2px 12px;">ICD9</th>
                        </tr>
                        <tr v-for="(v,k) in (dataRiwayatRMDokter.dataICD9||[])" :key="k">
                          <td style="padding:2px 12px;">
                            <span>{{v.api9_tindakan||"-"}}</span>
                          </td>
                          <td style="padding:2px 12px;">
                            <span>{{v.mi9_code||"-"}} - {{v.mi9_name||"-"}}</span>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="padding:0;">
                    <table style="border-collapse: collapse;width: 100%;" border="1">
                      <thead>
                        <tr style="background-color: #d3d3d3;">
                          <th colspan="99" style="text-align: left;padding: 2px 12px;">Riwayat Obat yang Diberikan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th style="padding: 2px 12px;">Obat</th>
                          <th style="padding: 2px 12px;" width="36">Dosis</th>
                          <th style="padding: 2px 12px;">Frekuensi</th>
                          <th style="padding: 2px 12px;" width="36">Rute</th>
                          <th style="padding: 2px 12px;">Instruksi</th>
                          <th style="padding: 2px 12px;" width="36">Jumlah</th>
                          <th style="padding: 2px 12px;" width="36">Satuan</th>
                        </tr>
                        <tr v-for="(v,k) in (dataRiwayatRMDokter.resepDokter||[])" :key="k" style="page-break-inside: avoid;">
                          <td style="padding:2px 12px;">{{v.ard_nama||"-"}}</td>
                          <td style="padding:2px 12px;">{{v.ard_dosis||"-"}} {{v.ard_satuan_dosis||"-"}}</td>
                          <td style="padding:2px 12px;">
                            <span v-if="v.ard_frekuensi !== 99999">{{v.mdo_name || "-"}}</span>
                            <span v-else>{{v.ard_frekuensi_lainnya || "-"}}</span>
                          </td>
                          <td style="padding:2px 12px;">
                            <span v-if="v.ard_jenis == 1">{{v.mob_rute_pemberian||"-"}}</span>
                            <span v-else> - </span>
                          </td>
                          <td style="padding:2px 12px;">{{v.ard_keterangan||"-"}}</td>
                          <td style="padding:2px 12px;">{{v.ard_jumlah||"-"}}</td>
                          <td style="padding:2px 12px;">{{v.ard_satuan||"-"}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr style="page-break-inside: avoid;">
                  <td style="padding:0;">
                    <table style="border-collapse: collapse;width: 100%;" border="1">
                      <thead>
                        <tr style="background-color: #d3d3d3;">
                          <th colspan="2" style="text-align: left;padding: 2px 12px;">Tindak Lanjut</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Tindak Lanjut</h4>
                              <p>{{dataRiwayatRMDokter.row.atld_is_edukasi == "Y"?'Edukasi':''}}</p>
                              <p>{{dataRiwayatRMDokter.row.atld_is_kontrol == "Y"?'Kontrol':''}}</p>
                              <p>{{dataRiwayatRMDokter.row.atld_is_rawat_inap == "Y"?'Rawat Inap':''}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <template v-if="dataRiwayatRMDokter.row.atld_is_edukasi == 'Y'">
                                <h4>Catatan Edukasi</h4>
                                <p>{{dataRiwayatRMDokter.row.atld_notes_edukasi||"-"}}</p>
                              </template>

                              <template v-if="dataRiwayatRMDokter.row.atld_is_kontrol == 'Y'">
                                <h4>Tanggal Pemeriksaan</h4>
                                <p v-if="dataRiwayatRMDokter.row.atld_notes_kontrol">
                                  {{dataRiwayatRMDokter.row.atld_notes_kontrol|moment("DD MMM YYYY")}}</p>
                                <span v-else> - </span>
                              </template>

                              <template v-if="dataRiwayatRMDokter.row.atld_is_rawat_inap == 'Y'">
                                <h4>Catatan Rawat Inap</h4>
                                <p>{{dataRiwayatRMDokter.row.atld_notes_rawat_inap||"-"}}</p>
                              </template>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </table>
            </div>
          </vue-html2pdf>
        </div>

        <table class="table table-bordered table-striped table-hover table-sm patient-table">
          <thead>
            <tr>
              <th>No. Reg</th>
              <th>Tanggal Reg</th>
              <th>Nama Dokter</th>
              <th>Keluhan</th>
              <th>Kajian</th>
              <th>Riwayat RM</th>
              <th>Riwayat Insiden</th>

              <th>Aksi</th>
            </tr>
          </thead>
          <tbody v-if="(dataList||[]).length">
            <template v-for="(v,k) in (dataList||[])">
              <tr :key="k">
                <td class="font-weight-semibold">
                  {{v.ar_reg_code||"-"}}
                </td>
                <td>
                  <span v-if="v.ar_reg_date">{{ v.ar_reg_date | moment("DD MMM YYYY")}}</span>
                  <span v-else>{{ v.ar_reg_date | moment("DD MMM YYYY")}}</span>
                </td>
                <td>
                  <strong>{{v.bu_full_name||"-"}}<small><br>{{v.mpo_name}}</small></strong>
                </td>

                <td>
                  <span>{{v.keluhan||"-"}}</span>
                </td>

                <td>
                  <a href="javascript:;" v-if="v.kajianPerawatId" @click="toDetail(v)" class="btn btn-sm btn-icon"
                    :class="v.arm_is_kajian_awal == 'Y' ? 'alpha-blue border-blue' : 'alpha-green border-green'"
                    v-b-tooltip.hover="'Lihat Kajian '+(v.arm_is_kajian_awal == 'Y' ? 'Awal' : 'Lanjutan')"><i
                      class="icon-file-eye"></i></a>
                  <span v-else>-</span>
                </td>

                <td><a href="javascript:;"
                    v-if="(v.kajianDokter || v.kajianDokterTerapi || v.kajianGizi || v.kajianFisio)"
                    @click="doOpenRiwayatRM(v)" v-b-tooltip.hover="'Lihat Riwayat Rekam Medis'"
                    class="btn btn-sm btn-icon alpha-orange border-orange"><i class="icon-file-eye"></i></a>
                  <span v-else>-</span>
                </td>

                <td>
                  <a href="javascript:;" @click="doOpenTindakan(v)" data-toggle="modal" data-target="#modalInsiden"
                    class="btn alpha-pink btn-sm border-pink btn-icon" data-popup="tooltip" v-b-tooltip.hover
                    title="Lihat Riwayat Insiden"><i class="icon-file-eye"></i></a>
                </td>
                <td>
                  <a href="javascript:;" v-if="v.kajianDokter" @click="downloadRM(v)"
                    class="btn alpha-success rounded-round btn-sm border-success text-success btn-icon"
                    data-popup="tooltip" v-b-tooltip.hover title="Unduh Resume Medis"><i class="icon-download"></i></a>
                  <span v-else> - </span>
                </td>
              </tr>
            </template>
          </tbody>
          <tbody v-else-if="!(dataList||[]).length && dataList">
            <tr>
              <th colspan="99" class="table-info text-center text-uppercase font-weight-semibold">
                Not Found Data
              </th>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <th colspan="99" class="table-info text-blue-700 text-uppercase font-weight-semibold">
                <div class="skeletal-comp mb-2"></div>
                <div class="skeletal-comp mb-2"></div>
                <div class="skeletal-comp"></div>
              </th>
            </tr>
          </tbody>
          <tfoot v-if="(dataList||[]).length && pageNow < data.data.last_page">
            <tr>
              <td class="text-center" colspan="99">
                <a href="javascript:;" @click="loadMore"
                  class="btn btn-outline-primary btn-sm font-weight-semibold">Tampilkan Rekam Medis Lainnya</a>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div :class="activeTab == 3 ? 'tab-pane fade show active' : 'tab-pane fade'" id="pasienTabArchive">
        <b-card-header>
          <div class="d-flex justify-content-between">
            <h6 class="card-title font-weight-semibold mb-0">Arsip Dokumen Rekam Medis</h6>
            <b-button variant="transparent" @click="openAdd=true;addNew()" class="btn-labeled btn-labeled-left bg-success">
              <b><i class="icon-file-text2"></i></b>
              <span>Unggah Arsip Baru</span>
            </b-button>
          </div>
        </b-card-header>
        <table class="table table-bordered table-striped table-hover table-sm patient-table">
          <thead>
            <tr>
              <th>No. Reg</th>
              <th>Tanggal Reg</th>
              <th>Nama Dokter</th>
              <th>Keluhan</th>
              <th>Rekam Medis</th>
              <th>Aksi</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(v,k) in (arsip||[])" :key="k">
              <td><span>{{v.aadr_reg_code||"-"}}</span></td>
              <td><span>{{v.aadr_reg_date |  moment("DD MMM YYYY")}}</span></td>
              <td><strong>{{v.bu_full_name||"-"}}<small><br>{{v.mpo_name||"-"}}</small></strong></td>
              <td><span>{{v.aadr_keluhan||"-"}}</span></td>
              <td>
                <b-button
                  @click="doOpenDetail(v)"
                  variant="transparent"
                  v-b-tooltip.hover="'Lihat Arsip Rekam Medis'"
                  class="btn-icon btn-sm alpha-orange border-orange"
                >
                  <i class="icon-file-eye"></i>
                </b-button>
              </td>
              <td>
                <b-button @click="deleteData(v.aadr_id)" variant="transparent" v-b-tooltip.hover="'Hapus Data'" class="btn-icon btn-sm alpha-danger border-danger">
                  <i class="icon-trash text-danger-800"></i>
                </b-button>
              </td>
            </tr>
            <tr v-if="!(arsip||[]).length">
              <td colspan="99" class="text-center">Tidak Ada Data Arsip</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div :class="activeTab == 4 ? 'tab-pane fade show active' : 'tab-pane fade'" id="pasienTabPJ">
        <div class="card-body">
          <table class="table table-borderless table-sm text-uppercase">
            <tbody>
              <tr>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Hubungan Keluarga</h4>
                    <p>{{row.arpj_hubungan_keluarga_text||"-"}}</p>
                  </div>
                </td>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Nama Penanggung Jawab</h4>
                    <p>{{row.arpj_fullname||"-"}}</p>
                  </div>
                </td>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Pekerjaan </h4>
                    <p v-if="row.arpj_pekerjaan !== 99999">{{row.pekerjaan_pj||"-"}}</p>
                    <p v-else>{{row.arpj_pekerjaan_text||"-"}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Jenis Kelamin</h4>
                    <p>{{row.gender_pj||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Agama </h4>
                    <p v-if="row.arpj_agama !== 99999">{{row.agama_pj||"-"}}</p>
                    <p v-else>{{row.arpj_agama_text||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>No Handphone</h4>
                    <p>+62{{row.arpj_phone_number}}</p>
                  </div>
                </td>
              </tr>

              <tr>
                <td colspan="3">
                  <div class="result_tab">
                    <h4>Alamat Domisili</h4>
                    <p>{{pjAddr||"-"}}</p>
                  </div>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
      <div :class="activeTab == 5 ? 'tab-pane fade show active' : 'tab-pane fade'" id="pasienTabKW">
        <div class="card-body">
          <table class="table table-borderless table-sm text-uppercase">
            <tbody>
              <tr>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Hubungan Keluarga</h4>
                    <p>{{row.arki_hubungan_keluarga_text||"-"}}</p>
                  </div>
                </td>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Nama Penanggung Jawab</h4>
                    <p>{{row.arki_fullname||"-"}}</p>
                  </div>
                </td>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Pekerjaan </h4>
                    <p v-if="row.arki_pekerjaan !== 99999">{{row.pekerjaan_ki||"-"}}</p>
                    <p v-else>{{row.arki_pekerjaan_text||"-"}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Jenis Kelamin</h4>
                    <p>{{row.gender_ki||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Agama </h4>
                    <p v-if="row.arki_agama !== 99999">{{row.agama_ki||"-"}}</p>
                    <p v-else>{{row.arki_agama_text||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>No Handphone</h4>
                    <p>+62{{row.arki_phone_number}}</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <b-modal v-model="openDetailKajian" :title="row.arm_is_kajian_awal == 'Y' ? 'Detail Kajian Awal': 'Detail Kajian Lanjutan'" size="xl" hide-footer>
      <DetailKajianAwal v-bind="passToSub" v-if="row.arm_is_kajian_awal == 'Y'" />
      <DetailKajianLanjutan v-bind="passToSubLanjutan" v-else />
    </b-modal>

    <b-modal v-model="openRwiayatRM" :title="'Detail Rekam Medis'" size="xl" hide-footer>
      <RiwayatRM v-bind="passToSubRM" />
    </b-modal>

    <b-modal v-model="openTindakan" :title="'Riwayat Insiden'" size="xl" hide-footer>
      <RiwayatInsiden v-bind="passToSubRiwayatInsiden" />
    </b-modal>

    <b-modal id="modalMedicalRecord" v-model="openDetail" title="Detail Arsip Rekam Medis" size="lg" ok-only ok-title="Tutup">
      <table class="table table-bordered">
        <tr>
          <td width="33%">
            <div class="result_tab">
              <h4>No. Reg</h4>
              <p>{{rowDetail.aadr_reg_code||"-"}}</p>
            </div>
          </td>
          <td width="33%">
            <div class="result_tab">
              <h4>Tgl. Reg</h4>
              <p>{{rowDetail.aadr_reg_date | moment("DD MMM YYYY")}}</p>
            </div>
          </td>
          <td width="33%">
            <div class="result_tab">
              <h4>Nama Dokter</h4>
              <p>
                <span class="font-weight-semibold">{{rowDetail.bu_full_name||"-"}}</span><br />
                <span>{{rowDetail.mpo_name||"-"}}</span>
              </p>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <div class="result_tab">
              <h4>Keluhan</h4>
              <p>{{rowDetail.aadr_keluhan||"-"}}</p>
            </div>
          </td>
        </tr>
      </table>
      <h6 class="mt-4 mb-2">Dokumen Rekam Medis</h6>
      <table class="table table-sm table-bordered table-striped">
        <thead>
          <tr>
            <th>No.</th>
            <th>Dokumen</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(v,k) in (rowDetail.aadr_dokumen||[])" :key="k">
            <td>{{k+1}}</td>
            <td>
              <b-button
                variant="transparent"
                class="btn-icon bg-blue"
                v-b-tooltip.hover="'Lihat Dokumen Rekam Medis'"
                :href="$parent.uploader(v)"
              >
                <i class="icon-file-text2 text-white"></i>
              </b-button>
            </td>
          </tr>
          <tr v-if="!(rowDetail.aadr_dokumen||[]).length">
            <td colspan="99" class="text-center">Tidak Ada Data</td>
          </tr>
        </tbody>
      </table>
    </b-modal>

    <validation-observer ref="VFormArsip">
    <b-form>
      <b-modal id="formMedicalRecord" v-model="openAdd" title="Tambah Arsip Rekam Medis" size="lg" @ok.prevent="submitArsip">
        <b-row>
          <b-col md="4">
            <b-form-group label="No. Registrasi" label-for="noReg">
              <b-form-input v-model="addData.aadr_reg_code" id="noReg" placeholder="e.g. RG00809613" />
              <VValidate 
                  name="No. Registrasi" 
                  v-model="addData.aadr_reg_code" 
                  :rules="{required : 1}"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Tgl. Registrasi" label-for="tglRegistrasi">
              <datepicker
                v-model="addData.aadr_reg_date"
                input-class="form-control"
                placeholder="Tanggal Registrasi" class="my-datepicker"
                id="tglRegistrasi"
                calendar-class="my-datepicker_calendar">
              </datepicker>
              <VValidate 
                  name="Tgl. Registrasi" 
                  v-model="addData.aadr_reg_date" 
                  :rules="{required : 1}"
              />
            </b-form-group>
          </b-col>
          <b-col md="5">
            <b-form-group label="Nama Dokter" label-for="namaDokter">
              <v-select
                placeholder="Pilih Dokter"
                :options="mDokterData"
                v-model="addData.aadr_dokter_id"
                class="med-selection"
                label="text"
                :clearable="true" :reduce="v=>v.value"
              >
                <template #option="{ text, poli }">
                  <span class="font-weight-semibold">{{ text }}</span> - {{ poli }}
                </template>
              </v-select>
              
              <VValidate 
                name="Nama Dokter" 
                v-model="addData.aadr_dokter_id" 
                :rules="{required : 1}"
              />

            </b-form-group>
          </b-col>
          <b-col md="8">
            <b-form-group label="Keluhan" label-for="keluhan">
              <b-form-textarea v-model="addData.aadr_keluhan" id="keluhan" placeholder="e.g. nyeri di paha kanan, ngilu di bagian lutut" rows="4" />
              
              <VValidate 
                name="Keluhan" 
                v-model="addData.aadr_keluhan" 
                :rules="{required : 1}"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <h6 class="my-2 font-weight-semibold">Dokumen Rekam Medis</h6>
        <table class="table table-sm table-bordered table-striped">
          <thead>
            <tr>
              <th>No.</th>
              <th>Dokumen</th>
              <th>Aksi</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(v,k) in (addData.aadr_dokumen||[])" :key="k">
              <td>{{k+1}}</td>
              <td>
                <Uploader v-model="addData.aadr_dokumen[k]" isDocument type="docimage" />
                <VValidate 
                  :name="'Dokumen '+(k+1)" 
                  v-model="addData.aadr_dokter_id" 
                  :rules="{required : 1}"
                />
              </td>
              <td>
                <b-button variant="transparent" @click="(addData.aadr_dokumen||[]).splice(k,1)" v-b-tooltip.hover="'Hapus Dokumen'" class="btn-icon btn-sm alpha-danger border-danger">
                  <i class="icon-trash text-danger-800"></i>
                </b-button>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td class="text-center" colspan="4">
                <b-button @click="(addData.aadr_dokumen||[]).push(null)" variant="info" class="btn-labeled btn-labeled-left">
                  <b><i class="icon-plus22"></i></b>
                  Tambah Dokumen
                </b-button>
              </td>
            </tr>
          </tfoot>
        </table>
      </b-modal>
    </b-form>
    </validation-observer>

  </div>
</template>

<script>
import $ from 'jquery'
const _ = global._
const moment = require('moment')
import Gen from '@/libs/Gen.js'

import DetailKajianAwal from './DetailKajianAwal.vue'
import DetailKajianLanjutan from './DetailKajianLanjutan.vue'
import RiwayatRM from './RiwayatRM.vue'
import RiwayatInsiden from './RiwayatInsiden.vue'
import VueHtml2pdf from 'vue-html2pdf'
import Datepicker from 'vuejs-datepicker'



export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object,
    data: Object,
    arsip: Array,
    mDokterData: Array
  },
  data() {
    return {
        activeTab: 1,
        pageNow: 1,

        openDetailKajian: false,
        openTindakan: false,
        rowReg: {},
        mPekerjaan: [],
        mAlatBantu: [],
        mSkrininGizi: [],
        mSkrininGiziV2: [],
        diagnosa: [],
        mSDKI: [],
        mSLKI: [],
        mSIKI: [],
        mPoli: [],
        mDokter: [],
        mKesadaran: [],
        mICD9: [],
        mICD10: [],
        mRadiologi: [],
        mLab: [],
        riwayatObat: [],
        resepDokter: [],
        mObatPreferensi: [],
        mDosis: [],
        openDetailKajianPerawat: false,
        openRwiayatRM: false,
        dataRiwayatRMDokter: {},
        dataRiwayatRMDokterTerapi: {},
        dataRiwayatRMDokterGizi: {},
        dataRiwayatRMFisio: {},
        dataRiwayatRMRadiologi: {},
        dataRiwyatRMPerawat: {},
        dataRiwayatRMLab: {},
        dataRiwyatRMPerawatIS: '',

        statusElektroterapi: [],
        statusMekanoTerapi: [],
        statusLatihan: [],
        mAlatKesehatan: [],
        mRacikan: [],
        historyKajianPerawat: [],
        historyKajianDokter: [],
        historyKajianDokterTerapi: [],
        historyKajianDokterGizi: [],
        historyKajianFisio: [],
        historyKajianRadiologi: [],
        historyKajianLab: [],

        dataRiwayatRMFarmasi: [],
        dataRiwayatRMFollowUp: {},

        dataInsiden: [],
        dataHais: [],

        downloadResumeMedis: false,
        
        dataICD9RM: [],
        dataICD10RM: [],
        dummyDoctors: [
          { name: 'dr. PRATISTA ADI KRISNA, Sp.PD', poli: 'POLI PENYAKIT DALAM	' }
        ],

        rowDetail: {},
        openDetail: false,

        addData: {},
        openAdd: false,
    }
  },
  components:{DetailKajianAwal,DetailKajianLanjutan,RiwayatRM,RiwayatInsiden,VueHtml2pdf,Datepicker},
  computed:{
    dataList(){return this.$parent.data.data },
    passToSub(){
      return _.assign({
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        row: this.row,
        rowReg: this.rowReg,
        mPekerjaan: this.mPekerjaan,
        mAlatBantu: this.mAlatBantu,
        mSkrininGizi: this.mSkrininGizi,
        mSkrininGiziV2: this.mSkrininGiziV2,
        diagnosa: this.diagnosa,
        mSDKI: this.mSDKI,
        mSLKI: this.mSLKI,
        mSIKI: this.mSIKI,
        mPoli: this.mPoli,
        mDokter: this.mDokter,
        mKesadaran: this.mKesadaran,
        historyKajianPerawat: this.historyKajianPerawat
      })
    },
    passToSubLanjutan(){
      return _.assign({
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        row: this.row,
        rowReg: this.rowReg,
        diagnosa: this.diagnosa,
        mSDKI: this.mSDKI,
        mSIKI: this.mSIKI,
        mKesadaran: this.mKesadaran,
        historyKajianPerawat: this.historyKajianPerawat
      })
    },
    passToSubRM(){
      return _.assign({
          dataRiwayatRMDokter: this.dataRiwayatRMDokter,
          dataRiwayatRMDokterTerapi: this.dataRiwayatRMDokterTerapi,
          dataRiwayatRMDokterGizi: this.dataRiwayatRMDokterGizi,
          dataRiwayatRMFisio: this.dataRiwayatRMFisio,
          dataRiwayatRMRadiologi: this.dataRiwayatRMRadiologi,
          dataRiwayatRMLab: this.dataRiwayatRMLab,
          dataRiwayatRMFarmasi: this.dataRiwayatRMFarmasi,
          row: this.row,
          rowReg: this.rowReg,
          dataRiwayatRMFollowUp: this.dataRiwayatRMFollowUp,
          
          historyKajianPerawat: this.historyKajianPerawat,
          historyKajianDokter: this.historyKajianDokter,
          historyKajianDokterTerapi: this.historyKajianDokterTerapi,
          historyKajianDokterGizi: this.historyKajianDokterGizi,
          historyKajianFisio: this.historyKajianFisio,
          historyKajianRadiologi: this.historyKajianRadiologi,
          historyKajianLab: this.historyKajianLab,
      })
    },
    passToSubRiwayatInsiden(){
      return _.assign({
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        row: this.row,
        rowReg: this.rowReg,
        dataInsiden: this.dataInsiden,
        dataHais: this.dataHais,
      })
    },
    ktpAddr(){
      if(this.row.ap_address && this.row.ap_rt && this.row.ap_rw && this.row.ap_kelurahan && this.row.ap_kecamatan && this.row.ap_kota){
        return (this.row.ap_address||"-") + "RT."+(this.row.ap_rt||"-")+"/"+(this.row.ap_rw||"-")+", DESA "+
        (this.row.ap_kelurahan||"-")+", KEC."+(this.row.ap_kecamatan||"-")+ "KAB/KOTA"+(this.row.ap_kota||"-")
      }else{
        return "-"
      }
    },
    domisiliAddr(){
      if(this.row.ap_dom_address && this.row.ap_dom_rt && this.row.ap_dom_rw && this.row.ap_dom_kelurahan && this.row.ap_dom_kecamatan && this.row.ap_dom_kota){
        return (this.row.ap_dom_address||"-") + "RT."+(this.row.ap_dom_rt||"-")+"/"+(this.row.ap_dom_rw||"-")+", DESA "+
        (this.row.ap_dom_kelurahan||"-")+", KEC."+(this.row.ap_dom_kecamatan||"-")+ "KAB/KOTA"+(this.row.ap_dom_kota||"-")
      }else{
        return "-"
      }
    },
    pjAddr(){
      if(this.row.arpj_address && this.row.arpj_rt && this.row.arpj_rw && this.row.arpj_kelurahan && this.row.arpj_kecamatan && this.row.arpj_kota){
        return (this.row.arpj_address||"-") + "RT."+(this.row.arpj_rt||"-")+"/"+(this.row.arpj_rw||"-")+", DESA "+
        (this.row.arpj_kelurahan||"-")+", KEC."+(this.row.arpj_kecamatan||"-")+ "KAB/KOTA"+(this.row.ap_dom_kota||"-")
      }else{
        return "-"
      }
    },
  },

  methods: {
    sinkronData(){
      this.$swal({
        icon: 'warning',
        title: 'Apakah Anda Yakin akan sinkronkan data ini?',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak, kembali'
      }).then(result => {
        if (result.value) {
          let data = {
            code: this.row.ap_code,
            type: "sinkron-data"
          }
          this.$parent.loadingOverlay = true
          Gen.apiRest(
            "/do/"+'Pasien',
            {data:data}, 
            "POST"
          ).then(res=>{
            this.$parent.loadingOverlay = false
            this.$parent.apiGet()
          })
        }
      })
    },
    
    addNew(){
      this.addData = {
        'aadr_ap_id' : this.$route.params.pageSlug,
        'aadr_dokter_id' : null,
        'aadr_reg_code' : null,
        'aadr_reg_date' : null,
        'aadr_keluhan' : null,
        'aadr_dokumen' : []
      }
    },

    deleteData(id){
      this.$swal({
        icon: 'warning',
        title: 'Apakah Anda Yakin akan menghapus data ini?',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak, kembali'
      }).then(result => {
        if (result.value) {
          let data = {
            id: id,
            type: "delete-arsip"
          }
          this.$parent.loadingOverlay = true
          Gen.apiRest(
            "/do/"+'Pasien',
            {data:data}, 
            "POST"
          ).then(res=>{
            this.$parent.loadingOverlay = false
            this.$parent.apiGet()
          })
        }
      })
    },
    doOpenDetail(v){
      this.rowDetail = v
      this.openDetail = true
    },
    downloadPRMRJ(row){
      let data = {exptype: 'xlsx', type: "export", id: row.ap_id}
      let self = this

      $.ajax({
          type: "POST",
          url: process.env.VUE_APP_API_URL + `/report/${'reportPRMRJ'}?token=IXs1029102asoaksoas102901290`,
          data: data,
          cache: false,
          xhrFields:{
              responseType: 'blob'
          },
          success: data => 
          {
              self.loadingOverlay = false
              var link = document.createElement('a')
              link.href = window.URL.createObjectURL(data)
              link.download = `Laporan-PRMRJ-${moment().format("YYYY-MM-DD")}.xlsx`
              link.click()
          },
          fail: data => {
              self.loadingOverlay = false
              alert('Not downloaded')
          }
      })
    },
    downloadRM(v){
      Gen.apiRest(
          "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+v.kajianDokter+'?regId='+v.arm_ar_id, 
      ).then(res=>{
          this.dataRiwayatRMDokter = res.data
          this.$refs.html2Pdf.generatePdf()
      })
    },
    doOpenTindakan(v){
        this.$parent.loadingOverlay = true        
        let data = {
            id: v.ar_id,
            type: "get-hais-insiden"
        }
        Gen.apiRest(
            "/do/"+'Pasien',
            {data:data}, 
            "POST"
        ).then(res=>{
            this.dataInsiden = res.data.dataInsiden
            this.dataHais = res.data.dataHais

            this.openTindakan = true
            this.$parent.loadingOverlay = false
    
        })
    },
    toDetail(v){
        this.$parent.loadingOverlay = true
        Gen.apiRest(
            "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+v.kajianPerawatId+'?regId='+v.arm_ar_id, 
        ).then(res=>{
            this.$parent.loadingOverlay = false
            this.openDetailKajian = true
            _.forEach(res.data, (v,k)=>{
                this.$set(this, k, v)
            })
        })
    },
    doOpenRiwayatRM(v){
        this.$parent.loadingOverlay = true
        
        if(v.kajianDokter){
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+v.kajianDokter+'?regId='+v.arm_ar_id, 
            ).then(res=>{
                this.$parent.loadingOverlay = false
                this.dataRiwayatRMDokter = res.data
                this.dataRiwayatRMDokter.Config = this.Config
                this.openRwiayatRM = true
            })
        }else{
          this.dataRiwayatRMDokter = {}
        }

        if(v.kajianDokterTerapi){
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+v.kajianDokterTerapi+'?regId='+v.arm_ar_id, 
            ).then(res=>{
                this.$parent.loadingOverlay = false
                this.dataRiwayatRMDokterTerapi = res.data
                this.dataRiwayatRMDokterTerapi.Config = this.Config
                this.openRwiayatRM = true
            })
        }else{
          this.dataRiwayatRMDokterTerapi = {}
        }

        if(v.kajianGizi){
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+v.kajianGizi+'?regId='+v.arm_ar_id, 
            ).then(res=>{
                this.$parent.loadingOverlay = false
                this.dataRiwayatRMDokterGizi = res.data
                this.dataRiwayatRMDokterGizi.Config = this.Config
                this.openRwiayatRM = true
            })
        }else{
          this.dataRiwayatRMDokterGizi = {}
        }

        if(v.kajianFisio){
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+v.kajianFisio+'?regId='+v.arm_ar_id, 
            ).then(res=>{
                this.$parent.loadingOverlay = false
                this.dataRiwayatRMFisio = res.data
                this.dataRiwayatRMFisio.Config = this.Config
                this.openRwiayatRM = true
            })
        }else{
          this.dataRiwayatRMFisio = {}
        }

        if(v.kajianRadiologi){
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+v.kajianRadiologi+'?regId='+v.arm_ar_id, 
            ).then(res=>{
                this.$parent.loadingOverlay = false
                this.dataRiwayatRMRadiologi = res.data
                this.dataRiwayatRMRadiologi.Config = this.Config
                this.openRwiayatRM = true
            })
        }else{
          this.dataRiwayatRMRadiologi = {}
        }
        
        if(v.kajianLab){
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+v.kajianLab+'?regId='+v.arm_ar_id, 
            ).then(res=>{
                this.$parent.loadingOverlay = false
                this.dataRiwayatRMLab = res.data
                this.dataRiwayatRMLab.Config = this.Config
                this.openRwiayatRM = true
            })
        }else{
            this.dataRiwayatRMLab = {}
        }
        

        Gen.apiRest(
            "/do/"+'RekamMedis',
            {data:{type:'get-obat-farmasi', regId: v.arm_ar_id}}
        ).then(res=>{
            let resp = res.data
            this.dataRiwayatRMFarmasi = resp.data
            this.loadingOverlay = false
        })
        
        Gen.apiRest(
            "/do/"+'RekamMedis',
            {data:{type:'get-riwayat-rekam-medis', ar_id: v.arm_ar_id}}
        ).then(res=>{
            let resp = res.data

            this.historyKajianPerawat = resp.historyKajianPerawat
            this.historyKajianDokter = resp.historyKajianDokter
            this.historyKajianDokterTerapi = resp.historyKajianDokterTerapi
            this.historyKajianDokterGizi = resp.historyKajianDokterGizi
            this.historyKajianFisio = resp.historyKajianFisio
            this.historyKajianRadiologi = resp.historyKajianRadiologi
            this.historyKajianLab = resp.historyKajianLab

            this.loadingOverlay = false
        })

        Gen.apiRest(
            "/get/"+'FollowUp'+'/'+this.row.ap_id+'?regId='+v.arm_ar_id, 
        ).then(res=>{
            this.loadingOverlay = false
            this.dataRiwayatRMFollowUp = res.data
            this.dataRiwayatRMFollowUp.Config = this.Config
            this.openRwiayatRM = true
        })


        setTimeout(()=>{
            this.$parent.loadingOverlay = false
            this.openRwiayatRM = true
        },2000)
    },
    changeTab(e){
        this.activeTab = e
    },
    toValidate(val){
      return {...val}
    },
    loadMore(){
        this.pageNow = this.pageNow + 1
        this.$parent.loadingOverlay = true

        let url = this.$parent.modulePage+'/'+this.$route.params.pageSlug
        Gen.apiRest(
            "/get/"+url, 
            {
            params: {page : this.pageNow}
            }
        ).then(res=>{
            this.$parent.loadingOverlay = false
            let newData = res.data.data.data
            for(let i = 0; i < (newData||[]).length; i++){
                this.$parent.data.data.push(newData[i])        
            }
            if(!this.isFound){
                this.$router.push({name : this.$route.name}).catch(()=>{})
            }
        }).catch(()=>{
            this.$parent.loadingOverlay = false
        })
    },
    submitArsip(){
      this.$refs['VFormArsip'].validate().then(success=>{
        if(success){
          this.$parent.loadingOverlay = true        
          let data = this.addData
          data.type = 'add-arsip'
          Gen.apiRest(
              "/do/"+'Pasien',
              {data:data}, 
              "POST"
          ).then(res=>{
              this.openAdd = false
              this.$parent.loadingOverlay = false
              this.$parent.apiGet()
              return this.$swal({
                  icon: 'success',
                  title: 'Data Arsip Berhasil Ditambahkan'
              })

          })
        }
      })
    },
    getConfigDynamic(master,value){
        let text = ''
        if(value){
            let index = master.findIndex(x => x.value == value)
            if(index !== -1){
                text = master[index]['text']
            }
        }
        return text
    },
  },
}
</script>