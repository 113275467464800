<template>
    <div class="card">
        <div class="bg-white card-header">
            <div class="d-flex">
                <a href="javascript:;" v-if="(dataList||[]).length" @click="downloadPRMRJ(row)"
                class="btn bg-green-600 btn-labeled btn-labeled-left ml-auto">
                <b><i class="icon-download"></i></b>
                Unduh PRMRJ
                </a>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table-bordered table-sm patient-table">
                <thead>
                    <tr>
                        <td colspan="2" style="text-align: center;">
                            <img :src="$parent.assetLocal('global_assets/images/logo-rsh-mark.png')" alt="Rumah Sakit Harapan" style="width:64px;height:64px;">
                        </td>
                        <td colspan="4">
                            <div style="text-align:center;text-transform:uppercase;">
                                <h4>Profil Ringkas Medis Rawat Jalan</h4>
                                <h6 class="font-weight-bold mb-0">Rumah Sakit Harapan<br />Magelang</h6>
                            </div>
                        </td>
                        <td colspan="3">
                            <table class="w-100 table-borderless table-sm">
                                <tr>
                                    <th class="border-0 p-0 bg-transparent">Nama</th>
                                    <td class="border-0 p-0 pt-1 px-1">:</td>
                                    <td class="border-0 p-0 pt-1">{{row.ap_fullname||"-"}}</td>
                                </tr>
                                <tr>
                                    <th class="border-0 p-0 pt-1 bg-transparent">No. RM</th>
                                    <td class="border-0 p-0 pt-1 px-1">:</td>
                                    <td class="border-0 p-0 pt-1">{{row.ap_code||"-"}}</td>
                                </tr>
                                <tr>
                                    <th class="border-0 p-0 pt-1 bg-transparent">Tgl. Lahir</th>
                                    <td class="border-0 p-0 pt-1 px-1">:</td>
                                    <td class="border-0 p-0 pt-1">{{row.ap_dob | moment("DD MMM YYYY")}}</td>
                                </tr>
                                <tr>
                                    <th class="border-0 p-0 pt-1 bg-transparent">No. NIK</th>
                                    <td class="border-0 p-0 pt-1 px-1">:</td>
                                    <td class="border-0 p-0 pt-1">{{row.ap_nik||"-"}}</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <th width="48">No</th>
                        <th>Tgl. Kunjungan</th>
                        <th>Diagnosa</th>
                        <th>ICD 10</th>
                        <th>Pemeriksaan Penunjang</th>
                        <th>Terapi</th>
                        <th>Riwayat Ranap dan Kunjungan Terakhir</th>
                        <th>Prosedur Bedah/Operasi saat Kunjungan Terakhir</th>
                        <th>Nama Dokter</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="(dataPrmrj||[]).length">
                        <tr v-for="(v,k) in (dataPrmrj||[])" :key="k">
                            <td>{{k+1}}</td>
                            <td>{{v.ar_reg_date | moment("DD MMM YYYY, HH:mm")}} WIB</td>
                            <td>
                                <ul v-if="(v.diagnose||[]).length">
                                    <li v-for="(v1,k1) in (v.diagnose||[])" :key="k1">{{v1}}</li>
                                </ul>
                                <span v-else> - </span>
                            </td>
                            <td>
                                <ul v-if="(v.icd10||[]).length">
                                    <li v-for="(v1,k1) in (v.icd10||[])" :key="k1">{{v1}}</li>
                                </ul>
                                <span v-else> - </span>
                            </td>
                            <td>
                                <ul v-if="(v.penunjang||[]).length">
                                    <li v-for="(v1,k1) in (v.penunjang||[])" :key="k1">{{v1}}</li>
                                </ul>
                                <span v-else> - </span>
                            </td>
                            <td>{{v.terapi||"-"}}</td>
                            <td>-</td>
                            <td>-</td>
                            <td>{{ v.bu_full_name||"-" }}</td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr>
                            <td colspan="99">Tidak ada data</td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
const _ = global._
const moment = require('moment')
import Gen from '@/libs/Gen.js'

export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object,
    data: Object,
    arsip: Array,
    mDokterData: Array,
    dataPrmrj: Array
  },
  data() {
    return {
        activeTab: 1,
        pageNow: 1,
    }
  },
  computed:{
    dataList(){return this.$parent.data.data },
  },

  methods: {
    downloadPRMRJ(row){
      let data = {exptype: 'xlsx', type: "export", id: row.ap_id}
      let self = this

      $.ajax({
          type: "POST",
          url: process.env.VUE_APP_API_URL + `/report/${'reportPRMRJ'}?token=IXs1029102asoaksoas102901290`,
          data: data,
          cache: false,
          xhrFields:{
              responseType: 'blob'
          },
          success: data => 
          {
              self.loadingOverlay = false
              var link = document.createElement('a')
              link.href = window.URL.createObjectURL(data)
              link.download = `Laporan-PRMRJ-${moment().format("YYYY-MM-DD")}.xlsx`
              link.click()
          },
          fail: data => {
              self.loadingOverlay = false
              alert('Not downloaded')
          }
      })
    },
  },
}
</script>