<template>
    <div>
        <ul class="nav nav-tabs nav-tabs-bottom nav-justified mt-2 mb-0">
            <li class="nav-item"><a href="javascript:;" @click="changeTab(1)" :class="activeTab == 1 ? 'nav-link active' : 'nav-link'" data-toggle="tab">INSIDEN</a></li>
            <li class="nav-item"><a href="javascript:;" @click="changeTab(2)" :class="activeTab == 2 ? 'nav-link active' : 'nav-link'" data-toggle="tab">HAIS</a></li>
        </ul>
        <div class="tab-content flex-tab overflow-hidden">
            <div :class="activeTab == 1 ? 'tab-pane fade overflow-hidden show active' : 'tab-pane fade overflow-hidden'" id="modRisLapIns">

            <div class="modal-body" v-if="(dataInsiden||[]).length">
               <div class="row">
                    <div class="col-md-12">
                        <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
                            <li v-for="(vP,kP) in dataInsiden||[]" :key="kP+'tab'" class="nav-item"><a href="javascript:;" @click="changeTabInsiden(kP)" :class="activeTabInsiden == kP ? 'nav-link active' : 'nav-link'" data-toggle="tab">#{{vP.bul_name}}</a></li>
                        </ul>
                    </div>
                </div>
            
                <div class="tab-content">
                    <div v-for="(vP,kP) in dataInsiden||[]" :key="kP+'navAnat'" :class="activeTabInsiden == kP ? 'tab-pane fade-show active' : 'tab-pane fade'">
                        <div class="row">
                            <div class="table-responsive">
                            <table class="table table-bordered">
                                <tbody>
                                <tr>
                                    <td width="50%">
                                    <div class="result_tab">
                                        <h4>Tanggal dan Waktu Kejadian</h4>
                                        <p>{{dataInsiden[kP].ali_date | moment("DD MMM YYYY")}} {{dataInsiden[kP].ali_time||"-"}}</p>
                                    </div>
                                    </td>
                                    <td width="50%">
                                    <div class="result_tab">
                                        <h4>Insiden</h4>
                                        <p>{{dataInsiden[kP].ali_insiden||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Jenis Insiden</h4>
                                        <p>{{getConfigDynamic(Config.mr.jenisInsiden,dataInsiden[kP].ali_jenis_insiden)||"-"}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Kronologi Insiden</h4>
                                        <p>{{dataInsiden[kP].ali_kronologi||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="50%">
                                    <div class="result_tab">
                                        <h4>Insiden Terjadi Pada</h4>
                                        <p v-if="dataInsiden[kP].ali_occurs_in !== 'L'">{{dataInsiden[kP].ali_occurs_in||"-"}}</p>
                                        <p v-else>{{dataInsiden[kP].ali_occurs_in_lainnya||"-"}}</p>
                                    
                                    </div>
                                    </td>
                                    <td width="50%">
                                    <div class="result_tab">
                                        <h4>Insiden Menyangkut Pasien</h4>
                                        <p v-if="dataInsiden[kP].ali_menyangkut_pasien !== 'L'">{{dataInsiden[kP].ali_menyangkut_pasien||"-"}}</p>
                                        <p v-else>{{dataInsiden[kP].ali_menyangkut_pasien_lainnya||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="50%">
                                    <div class="result_tab">
                                        <h4>Tempat Insiden</h4>
                                        <p>{{dataInsiden[kP].ali_tempat_insiden||"-"}}</p>
                                    </div>
                                    </td>
                                    <td width="50%">
                                    <div class="result_tab">
                                        <h4>Insiden Terjadi pada Pasien</h4>
                                        <p>{{dataInsiden[kP].mi_name||"-"}}</p>

                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="50%">
                                    <div class="result_tab">
                                        <h4>Unit Kerja Penyebab</h4>
                                        <p>{{dataInsiden[kP].ali_unit_kerja||"-"}}</p>
                                    </div>
                                    </td>
                                    <td width="50%">
                                    <div class="result_tab">
                                        <h4>Dampak insiden terhadap Pasien</h4>
                                        <p>{{dataInsiden[kP].ali_akibat_insiden||"-"}}</p>

                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" v-if="dataInsiden[kP].ali_is_kajian_sama == 'Y'">
                                    <div class="result_tab">
                                        <h4>Tindakan yang dilakukan segera setelah kejadian, dan hasilnya</h4>
                                        <p>{{dataInsiden[kP].ali_langkah_dan_tindakan_unit_kerja||"-"}}</p>
                                    </div>
                                    <div class="result_tab mt-2">
                                        <h4>Tindakan dilakukan oleh</h4>
                                        <p>{{dataInsiden[kP].ali_tindakan_oleh||"-"}}</p>
                                        <p v-if="dataInsiden[kP].ali_tindakan_oleh == 'Petugas Lainnya' || dataInsiden[kP].ali_tindakan_oleh == 'Tim'">{{dataInsiden[kP].ali_tindakan_by||"-"}}</p> <br/>
                                    </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                            <div class="table-responsive">
                            <table class="table table-bordered">
                                <tbody>
                                <tr>
                                    <td colspan="2">
                                    <div class="result_tab">
                                        <h4>Apakah kejadian yang sama pernah terjadi di Unit Kerja lain?</h4>
                                        <p>{{dataInsiden[kP].ali_is_kajian_sama == 'Y' ? 'Ya' : 'Tidak'}}</p>
                                    </div>
                                    <div class="result_tab mt-2">
                                        <h4>Kapan? dan Langkah / tindakan apa yang telah diambil pada Unit kerja tersebut untuk mencegah terulangnya kejadian yang sama?</h4>
                                        <p>{{dataInsiden[kP].ali_langkah_dan_tindakan_unit_kerja||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="50%">
                                    <div class="result_tab">
                                        <h4>Probabilitas</h4>
                                        <p>{{dataInsiden[kP].ali_probabilitas||"-"}}</p>
                                    </div>
                                    </td>
                                    <td width="50%">
                                    <div class="result_tab">
                                        <h4>Grading</h4>
                                        <p>{{dataInsiden[kP].ali_grading||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <span v-else class="mt-3 d-block">Tidak Ada Data Insiden</span>
            </div>
            <div :class="activeTab == 2 ? 'tab-pane fade overflow-hidden show active' : 'tab-pane fade overflow-hidden'" id="modRisHais">
                <template v-if="(dataHais||[]).length">
                <div class="modal-header d-block">
                    <ul class="nav nav-tabs nav-tabs-bottom mb-0">
                    <li v-for="(v,k) in (dataHais||[])" :key="k+'hais'" class="nav-item"><a href="javascript:;" :class="activeTabHais == (k+1) ? 'nav-link active' : 'nav-link'" data-toggle="tab" data-target="#tabHais1" @click="changeTabHais(k+1)">Kejadian #{{k+1}}</a></li>
                    </ul>
                </div>
                <div class="modal-body">
                    <div class="tab-content">
                        <div v-for="(v,k) in (dataHais||[])" :key="k+'hais2'" 
                        :class="activeTabHais == (k+1) ? 'tab-pane fade show active': 'tab-pane fade'" id="tabHais1">
                            <div class="table-responsive">
                            <table class="table table-bordered">
                                <tbody class="detail_bo">
                                <tr>
                                    <td>
                                    <div class="result_tab">
                                        <h4>No. Reg</h4>
                                        <p>{{v.ar_reg_code||"-"}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Perawat</h4>
                                        <p>{{v.bu_full_name||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Tanggal</h4>
                                        <p>{{v.aha_created_date | moment("DD MMM YYYY")}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Kategori</h4>
                                        <p> {{v.mh_name||"-"}}
                                        </p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                    <div class="result_tab">
                                        <h4>Komponen</h4>
                                        <ul>
                                        <li v-for="(v1,k1) in (v.aha_komponen||[])" :key="k1">{{v1||"-"}}</li>
                                        </ul>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                    <div class="result_tab">
                                        <table class="table table-bordered  table-sm patient-table">
                                        <thead>
                                            <tr>
                                            <th>Kejadian</th>
                                            <th>Catatan</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="(v2,k2) in (v.aha_kejadian||[])" >
                                                <tr v-if="v2.value" :key="k2">
                                                <td>{{v2.name||"-"}}</td>
                                                <td>{{v2.val_name||"-"}}</td>
                                                </tr>
                                            </template>
                                        </tbody>
                                        </table>
                                    </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </div>
                </template>
                <span v-else class="mt-3 d-block">Tidak ada Data Hais</span>
            </div>
        </div>
    </div>
</template>


<script>
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')

export default{
    props: {
        isAdd: Object,
        mrValidation: Object,
        Config: Object,
        dataInsiden: Object,
        dataHais: Array,
        row: Object,
        rowReg: Object,
    },
    data(){
        return {
            activeTab : 1,
            activeTabHais : 1,
            activeTabInsiden: 0,
        }
    },
    methods: {
        changeTab(e){
            this.activeTab = e
        },
        
        changeTabInsiden(e){
            this.activeTabInsiden = e
        },
        
        changeTabHais(e){
            this.activeTabHais = e
        },
        
        getConfigDynamic(master,value){
            let text = ''
            if(value){
                let index = master.findIndex(x => x.value == value)
                if(index !== -1){
                    text = master[index]['text']
                }
            }
            return text
        },
    },
}

</script>
